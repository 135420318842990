<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>Setting Account</CCardHeader>
                    <CCardBody>
                        <CForm @submit="onSubmit">
                            <CRow>
                                <CCol sm="6">
                                    <CInput
                                        label="Username"
                                        placeholder="Username"
                                        v-model="data_user.member_username"
                                        disabled
                                    />
                                </CCol>
                                <CCol sm="6">
                                    <CInput
                                        label="Name"
                                        placeholder="Name"
                                        v-model="data_user.member_name"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="6">
                                    <CInput
                                        label="Mobile No"
                                        placeholder="Mobile No"
                                        v-model="data_user.member_mobile_no"
                                    />
                                </CCol>
                                <CCol sm="6">
                                    <CInput
                                        label="Email"
                                        placeholder="Email"
                                        v-model="data_user.member_email"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="6">
                                    <CInput
                                        label="Bank Name"
                                        placeholder="Bank Name"
                                        v-model="data_user.withdraw_bank_name"
                                    />
                                </CCol>
                                <CCol sm="6">
                                    <CInput
                                        label="Bank Account No"
                                        placeholder="Bank Account No"
                                        v-model="data_user.withdraw_bank_account_no"
                                    />
                                </CCol>
                                <!-- <CCol sm="6">
                                    <CInput
                                        label="Change Password"
                                        placeholder="Change Password"
                                        v-model="data_user.password"
                                    />
                                </CCol> -->
                            </CRow>
                            <CRow>
                                <CCol sm="6" align="left">
                                    <CButton
                                        color="success"
                                        class="m-2"
                                        type="submit"
                                    >
                                    Submit
                                    </CButton>
                                </CCol>
                                <CCol sm="6" align="right">
                                    <CButton
                                        color="warning"
                                        class="m-2"
                                        @click="updatePassword()"
                                    >
                                    Change Password
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    <CModal
      title="Change Password"
      color="warning"
      :show.sync="changePasswordModal"
    >
      <CForm @submit="submitChangePassword">
      <CRow>
        <CCol sm="12">
          <CInput
            label="Password"
            v-model="fieldupdatePassword.password"
          />
        </CCol>
        <CCol sm="6">
          <CButton color="warning" square size="sm" @click="modalKembali">Cancel</CButton>
        </CCol>
        <CCol sm="6" align="right">
          <CButton color="success" type="submit" square size="sm">Submit</CButton>
        </CCol>
      </CRow>
      </CForm>
    </CModal>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AccountSetting',
  data(){
    return{
        urlApi:this.$store.state.urlApi,
        changePasswordModal:false,
        data_user:[],
        fieldupdatePassword:{
            password:null
        },
        edit_user:{
            member_name:null,
            member_mobile_no:null,
            member_email:null,
            withdraw_bank_name:null,
            withdraw_bank_account_no:null
        }
    }
  },
  mounted(){
    axios.get(`${this.urlApi}/api/user-details/${localStorage.getItem('id')}`).then(response => {
      this.data_user = response.data
    })
  },
  methods:{
    onSubmit(e){
    e.preventDefault()
        this.edit_user.member_name = this.data_user.member_name
        this.edit_user.member_mobile_no = this.data_user.member_mobile_no
        this.edit_user.member_email = this.data_user.member_email
        this.edit_user.withdraw_bank_name = this.data_user.withdraw_bank_name
        this.edit_user.withdraw_bank_account_no = this.data_user.withdraw_bank_name

        axios.put(`${this.urlApi}/api/user-details/${localStorage.getItem('id')}`,this.edit_user).then(response => {
            console.log(response.data)
            this.$router.push({ name: 'Dashboard' })
        })

    },

    updatePassword(item){
      this.changePasswordModal = true
    },

     modalKembali(){
      this.fieldupdatePassword.password = ''
      this.changePasswordModal = false
    },

    submitChangePassword(evt) {
      evt.preventDefault()

      axios.put(`${this.urlApi}/api/reset-password/update/${localStorage.getItem('id')}`,this.fieldupdatePassword).then(response => {
        localStorage.clear();
        location.href = ('/#/pages/login')
      })
    },
  }
}
</script>